<template>
  <div class="mizun-box ">
    <titleHeader></titleHeader>
    <img src="../assets/images/my/lzg.jpg" alt="">

    
    
  </div>
</template>

<script>
export default {
  components: {
    
  },
  data() {
    return {
      id:'',
      
    }
    
  },
  
  created(){
    
    
  },
  mounted() {
   if(!this.$cookies.get("Authorization")){
     this.$router.replace("login");
   }
    
  },
  methods: {
   
  },
};
</script>

<style scoped lang="scss">
.mizun-box{
  img{
    display: block;
  }
  
  
}
</style>
